<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                再添新伙伴！非凸算法正式上线国投证券（原“安信证券”）
              </p>
              <div class="fst-italic mb-2">2023年4月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国投证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/33/01-安信上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸算法正式上线国投证券，达成总对总合作，共同建设算法及交易的生态圈。非凸科技将充分发挥自身科技创新优势，积极响应客户需求，做到精益求精、快速迭代、持续优化，助力国投证券不断提升展业能力、丰富算法策略以及完善服务体系等，从而为机构客户提供差异化、多样化的算法服务。
                  </p>
                  <p>
                    国投证券坚持以客户为中心，深耕中国市场，为个人和机构客户提供综合金融服务。与此同时，国投证券将科技与业务深度融合，积极布局金融科技，稳步实践数字化转型，充分赋能金融业务发展。此次合作，非凸科技将为国投证券提供更多技术支持，推动创新业务落地，为算法交易的专业化发展提供科技动能。
                  </p>
                  <p>
                    ChatGPT诞生后，全球掀起大模型浪潮，人工智能进入高速发展阶段，在量化投资领域的应用也变得更加成熟。非凸科技结合机器学习、深度学习、强化学习等人工智能技术，解决规模交易中难以避免的冲击成本问题，从而帮助机构客户显著提高超额收益。然而，算法交易能力的输出不仅需要强连续性、高可靠性，还需要优异的绩效表现能力。
                  </p>
                  <p>
                    这其中，算力是算法和数据的基础设施。非凸科技每年投入千万级研发成本支持算力优化，积极探索应用场景，希望以核心技术优势引领行业对交易执行的更高要求。与此同时，面对不同机构客户需求，可以提供专业化交易策略及深度定制开发，一站式解决机构客户交易痛点，为机构业务拓展和服务提供有力支持。
                  </p>
                  <p>
                    技术驱动才能促进多方共赢。非凸科技将在算法交易的技术应用方面，追求更高，做到极致的性能；追求更快，快速响应新业务、新需求；追求更强，建设安全、稳定、可靠的基础设施。也将进一步加强对算法总线实际需求的调研分析，为行业带来更多产品和服务创新，从而助力机构客户能享受到金融科技发展带来的红利。
                  </p>
                  <p>
                    未来，非凸科技与国投证券的合作将继续深入，持续提升交易服务优势，打造专业服务品牌，满足机构客户多样化、专业化的交易需求。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News33",
};
</script>

<style></style>
